import "./App.css";
import Photowall from "./PhotoWall";
import { useParams } from "react-router-dom";
function App() {
  const { id } = useParams();
  return (
    <div className="App">
      <Photowall dynamicId={id.toLowerCase()} />
    </div>
  );
}

export default App;
